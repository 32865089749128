import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { getIEP, newModule, updateModule } from "../api"
import type { Module } from "../types/Module"
import { isEmailValid } from "../lib/util"
import WaitPopup from "./WaitPopup"

interface Props {
	id?: number
	handleClose: Function
	newState: boolean
}

const blankIEP: Module = {
	abbrv: "",
	alias: null,
	aliases: null,
	alternate_email: null,
	ccm_alias: null,
	contact_email: "",
	is_nam: null,
	module_type: "",
	name: ""
}

export default function (props: Props) {
	const [IEP, setIEP] = React.useState<Module | undefined>()
	const [name, setName] = React.useState("")
	const [abbrv, setAbbrv] = React.useState("")
	const [email, setEmail] = React.useState("")
	let openWait = true

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (IEP) {
			setName(IEP.name)
			setEmail(IEP.contact_email)
			setAbbrv(IEP.abbrv)
		}
	}, [IEP])

	function loadPageInfo() {
		if (props.newState) {
			setIEP(blankIEP)
		} else if (props.id) {
			getIEP(props.id, (iep: any) => {
				setIEP(iep)
			})
		}
	}

	function handleSave() {
		if (isIepValid()) {
			IEP.name = name
			IEP.contact_email = email
			IEP.abbrv = abbrv
			IEP.module_type = "iep"
			if (props.newState) {
				newModule(IEP).then(() => { props.handleClose() })
			} else {
				updateModule(IEP).then(() => { props.handleClose() })
			}
		} else {
			alert("Information is invalid or in the wrong format.")
		}
	}

	function isIepValid() {
		if (name && isEmailValid(email)) {
			return true
		}
		return false
	}

	let emailError = false
	if (isEmailValid(email)) {
		emailError = false
	} else {
		emailError = true
	}

	if (IEP) {
		openWait = false
	}

	return (
		<div>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Grid container>
				<Grid item sm={12}>
					<Box>{props.newState ? <h3>Add IEP</h3> : <h3>Edit IEP</h3>}</Box>
				</Grid>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="NAME"
								value={name}
								onChange={(event: any) => {
									setName(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="ABBREVIATION"
								value={abbrv}
								onChange={(event: any) => {
									setAbbrv(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={6} style={{ margin: 10 }}>
							<TextField
								error={emailError ? true : false}
								label="EMAIL"
								type="email"
								value={email}
								onChange={(event: any) => {
									setEmail(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={12}>
							<Box style={{ float: "right" }}>
								<Button
									style={{ margin: 5 }}
									onClick={() => {
										props.handleClose()
									}}
									variant="outlined"
									color="primary">
									Close
								</Button>
								<Button
									style={{ margin: 5 }}
									onClick={handleSave}
									variant="outlined"
									color="primary">
									Save
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
