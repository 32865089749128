import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { getCustomer, newModule, updateModule } from "../api"
import { isEmailValid } from "../lib/util"
import WaitPopup from "./WaitPopup"

interface Props {
	id: number
	handleClose: Function
	newState: boolean
}

export default function (props: Props) {
	const [customer, setCustomer] = React.useState<any | undefined>()
	const [name, setName] = React.useState("")
	const [abbrv, setAbbrv] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [isNam, setIsNam] = React.useState<any | undefined>(false)
	let openWait = true

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (customer) {
			setName(customer.name)
			setEmail(customer.email)
			setAbbrv(customer.abbrv)
			setIsNam(customer.is_nam)
		}
	}, [customer])

	function loadPageInfo() {
		if (props.newState) {
			setCustomer({ name: "", contact_email: "", abbrv: "" })
		} else {
			getCustomer(props.id, setCustomer)
		}
	}

	function handleSave() {
		if (isCustomerValid()) {
			customer.name = name
			customer.contact_email = email || ""
			customer.abbrv = abbrv
			customer.is_nam = isNam
			customer.module_type = "customer"
			if (props.newState) {
				newModule(customer).then(() => props.handleClose())
			} else {
				updateModule(customer).then(() => props.handleClose())
			}
		} else {
			alert("Information is invalid or in the wrong format.")
		}
	}

	function handleIsNamChange(event) {
		setIsNam(event.target.checked)
	}

	function isCustomerValid() {
		if (name) {
			if (email) {
				return isEmailValid(email)
			}
			return true
		}
		return false
	}

	let emailError = false
	if (email && isEmailValid(email)) {
		emailError = false
	} else {
		emailError = true
	}

	if (customer) {
		openWait = false
	}

	return (
		<div>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Grid container>
				<Grid item sm={12}>
					<Box>{props.newState ? <h3>Add Customer</h3> : <h3>Edit Customer</h3>}</Box>
				</Grid>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="NAME"
								value={name}
								onChange={(event: any) => {
									setName(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="ABBREVIATION"
								value={abbrv}
								onChange={(event: any) => {
									setAbbrv(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								error={emailError ? true : false}
								label="EMAIL"
								type="email"
								value={email}
								onChange={(event: any) => {
									setEmail(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>

						<Grid item sm={12} style={{ margin: 10 }}>
							<FormControl component="fieldset">
								<FormGroup aria-label="position" row>
									<FormControlLabel
										checked={isNam}
										onChange={handleIsNamChange}
										control={<Checkbox color="primary" />}
										label="Is NAM?"
									/>
								</FormGroup>
							</FormControl>
						</Grid>
						<Grid item sm={12}>
							<Box style={{ float: "right" }}>
								<Button
									style={{ margin: 5 }}
									onClick={() => {
										props.handleClose()
									}}
									variant="outlined"
									color="primary">
									Close
								</Button>
								<Button
									style={{ margin: 5 }}
									onClick={handleSave}
									variant="outlined"
									color="primary">
									Save
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
