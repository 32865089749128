import React from "react"

import {
	Box,
	Button,
	IconButton,
	MenuItem,
	Modal,
	Popover,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
} from "@material-ui/core"

import {
	Add as AddIcon,
	Launch as LaunchIcon,
	Search as SearchIcon,
	Cancel as CancelIcon,
} from "@material-ui/icons"

import { Link } from "react-router-dom"

import {
	InvoiceRecordRemoveManifest,
	removeInvoiceRecordPossibleMatches,
	removePossibleDisputes,
	updateInvoiceRecord,
	validatedInvoiceRecordWithNewManifest,
} from "../../../api"
import { formatCurrency, formatDateMMDDYYYY } from "../../../lib/formatters"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import { authStore } from "../../../stores/authStore"
import type { DisputeReasonDTO } from "../../../types/tables/DisputeReason"
import type { IEPInvoicePlus } from "../../../types/tables/IEPRecord"
import type { RecordStatusDTO } from "../../../types/tables/RecordStatus"
import AdditionalFields from "../../AdditionalFields"
import DuplicateDisplay from "../../DuplicateDisplay"
import InvRecFilter from "../../InvRecFilter"
import MultiManifestDisplay from "../../MultiManifestDisplay"
import RecordSearch from "../../RecordSearch"
import TableToExcel from "../../TableToExcel/TableToExcel"
import { useStyles } from "./styles"
import { type DataCCMWorkflow, type HeadCell, HeaderDataInvoiceRecons } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataCCMWorkflow) => void
	order: Order
	orderBy: string
	headCells: HeadCell[]
	viewTrinium?: boolean
	viewReason?: boolean
	viewReview?: boolean
	viewPopOver?: boolean
	quickSearchDisplays?: boolean
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const {
		classes,
		order,
		orderBy,
		onRequestSort,
		headCells,
		viewTrinium,
		viewReason,
		viewReview,
		viewPopOver,
		quickSearchDisplays,
	} = props
	const createSortHandler =
		(property: keyof DataCCMWorkflow) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				{viewPopOver && (
					<TableCell
						padding={"checkbox"}
						style={{
							textAlign: "center",
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						{"..."}
					</TableCell>
				)}
				{viewTrinium && (
					<TableCell
						padding={"checkbox"}
						style={{
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						{"..."}
					</TableCell>
				)}
				{viewReview && (
					<TableCell
						padding={"checkbox"}
						style={{
							textAlign: "center",
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						{"..."}
					</TableCell>
				)}
				{viewReason && (
					<TableCell
						key={"dispute_reason"}
						align={false ? "right" : "left"}
						sortDirection={orderBy === "dispute_reason" ? order : false}
						padding={"checkbox"}
						style={{
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
							textAlign: "center",
						}}>
						<TableSortLabel
							active={orderBy === "dispute_reason"}
							direction={orderBy === "dispute_reason" ? order : "asc"}
							onClick={createSortHandler("dispute_reason")}
							hideSortIcon={true}>
							{"REASON"}
							{orderBy === "dispute_reason" ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				)}
				{quickSearchDisplays && headCells.find((element) => element.id === "invoice_num") && (
					<TableCell
						padding={"checkbox"}
						style={{
							textAlign: "center",
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						<TableSortLabel
							active={orderBy === headCells.find((element) => element.id === "invoice_num").id}
							direction={
								orderBy === headCells.find((element) => element.id === "invoice_num").id
									? order
									: "asc"
							}
							onClick={createSortHandler(
								headCells.find((element) => element.id === "invoice_num").id,
							)}
							hideSortIcon={true}>
							{headCells.find((element) => element.id === "invoice_num").label}
							{orderBy === headCells.find((element) => element.id === "invoice_num").id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				)}
				{quickSearchDisplays && headCells.find((element) => element.id === "invoice_dt") && (
					<TableCell
						padding={"checkbox"}
						style={{
							textAlign: "center",
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						<TableSortLabel
							active={orderBy === headCells.find((element) => element.id === "invoice_dt").id}
							direction={
								orderBy === headCells.find((element) => element.id === "invoice_dt").id
									? order
									: "asc"
							}
							onClick={createSortHandler(
								headCells.find((element) => element.id === "invoice_dt").id,
							)}
							hideSortIcon={true}>
							{headCells.find((element) => element.id === "invoice_dt").label}
							{orderBy === headCells.find((element) => element.id === "invoice_dt").id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				)}
				{headCells.map((headCell) => {
					if (headCell.label.toUpperCase() === "REASON") {
						return
					}
					if (headCell.id.toUpperCase() === "INVOICE_NUM") {
						return
					}
					if (headCell.id.toUpperCase() === "INVOICE_DT") {
						return
					}
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
				{viewPopOver && (
					<TableCell
						padding={"checkbox"}
						style={{
							textAlign: "center",
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						{"..."}
					</TableCell>
				)}
			</TableRow>
		</TableHead>
	)
}

function getModalStyle() {
	return {
		top: `${10}%`,
		left: `${3}%`,
		overflow: "auto",
	}
}

interface Props {
	invoiceData: IEPInvoicePlus[]
	statusData: RecordStatusDTO[]
	disputeReasonData: DisputeReasonDTO[]
	viewTrinium?: boolean
	viewReason?: boolean
	viewReview?: boolean
	viewPopOver?: boolean
	disableControls?: boolean
	maxHeight?: number
	PopoverControl?: Function
	displayAllItems?: boolean
	disableDropdown?: boolean
	isComplete?: boolean
	isUnlocked?: boolean
	quickSearchDisplays?: boolean
	searchRecordId?: string | undefined
}

export default function InvoiceNeedsReview(props: Props) {
	const classes = useStyles()
	const defaultSort = props.quickSearchDisplays ? "dt_in" : "updated_dt"
	const [order, setOrder] = React.useState<Order>("desc")
	const [orderBy, setOrderBy] = React.useState<string>(defaultSort)
	const [selectedItem, setSelected] = React.useState<number>()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [modalStyle] = React.useState(getModalStyle)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(
		props.displayAllItems ? props.invoiceData.length : 200,
	)
	const [invoiceRecordID, setInvoiceRecordID] = React.useState<any | undefined>()
	const [openModal, setOpenModal] = React.useState(false)
	const [openMultiManifestModal, setOpenMultiManifestModal] = React.useState(false)
	const [manifestSelected, setManifestSelected] = React.useState<any | undefined>()
	const [manifestUnselected, setManifestUnselected] = React.useState<any | undefined>()
	const [multimanifestData, setMultiManifestData] = React.useState<any | undefined>()
	const [searchText, setSearchText] = React.useState<string | undefined>(props.searchRecordId)
	const [openSearchModal, setOpenSearchModal] = React.useState<any | undefined>(false)
	const [openAdditionalFieldsModal, setOpenAdditionalFieldsModal] = React.useState<any | undefined>(
		false,
	)
	const [searchRecord, setSearchRecord] = React.useState<any | undefined>()
	const [invoiceRecords, setInvoiceRecords] = React.useState<any | undefined>()
	const [resetSearch, setResetSearch] = React.useState<any | undefined>(false)
	const [openDuplicateModal, setOpenDuplicateModal] = React.useState<boolean>(false)
	const [duplicateInvoiceID, setDuplicateInvoiceID] = React.useState<number | undefined>()
	const auth = authStore.use()
	const me = auth.me

	React.useEffect(() => {
		if (props.invoiceData && props.invoiceData.length > 0) {
			const data = props.invoiceData
			data.sort((a, b) => {
				if (a.updated_dt instanceof Date && b.updated_dt instanceof Date) {
					return b.updated_dt.getTime() - a.updated_dt.getTime()
				}
				return -1
			})
			data.forEach((item) => {
				if (item) {
				}
				item.total_rebill = getRebillTotal(item)
				item.profit_loss = (item.total_rebill - item.total_billed).toFixed(2).toString()
			})
			data.forEach((item) => {
				if (item.dispute_reason_id) {
					item.dispute_reason = props.disputeReasonData.find(
						(element) => element.id_ === item.dispute_reason_id,
					)?.reason
				}
			})
			setInvoiceRecords(data)
		} else if (props.invoiceData) {
			setInvoiceRecords(props.invoiceData)
		}
	}, [props.invoiceData])

	React.useEffect(() => {
		if (manifestSelected) {
			const refObj = invoiceRecords.find((row: any) => row.id_ === invoiceRecordID)
			refObj.manifest_num = manifestSelected
			const validationObj: any = { manifest: manifestSelected, invRecID: invoiceRecordID }

			validatedInvoiceRecordWithNewManifest(validationObj, () => {
				removeInvoiceRecordPossibleMatches(invoiceRecordID, () => {
					setManifestSelected(undefined)
				})
			})
		}
	}, [manifestSelected])

	React.useEffect(() => {
		if (manifestUnselected) {
			const refObj = { invRecID: invoiceRecordID, manifest: manifestUnselected }
			InvoiceRecordRemoveManifest(refObj, () => {
				setManifestUnselected(undefined)
			})
		}
	}, [manifestUnselected])

	React.useEffect(() => {
		if (searchText && props.invoiceData) {
			const filteredInvoiceRecs = props.invoiceData.filter((item) => {
				if (item.chassis && item.chassis.toUpperCase().includes(searchText.toUpperCase())) {
					return true
				}
				if (item.container1 && item.container1.toUpperCase().includes(searchText.toUpperCase())) {
					return true
				}
				if (
					item.matches &&
					item.matches.length > 0 &&
					item.matches[0].full_manifest.toUpperCase().includes(searchText.toUpperCase())
				) {
					return true
				}
				if (item.id_.toString() === searchText) {
					return true
				}
			})

			setInvoiceRecords(filteredInvoiceRecs)
		}
		handleCloseSearchModal()
	}, [searchText, props.invoiceData])

	React.useEffect(() => {
		if (resetSearch) {
			setInvoiceRecords(props.invoiceData)
			setResetSearch(false)
			setSearchText(undefined)
		}
		handleCloseSearchModal()
	}, [resetSearch])

	const handleRequestSort = (
		_event: React.MouseEvent<unknown>,
		property: keyof DataCCMWorkflow,
	) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (_event: React.MouseEvent<unknown>, name: number) => {
		let newSelected = -1
		newSelected = name
		setSelected(newSelected)
	}

	const handleDoubleClick = (event: any, name: number, id: number) => {
		if (props.PopoverControl) {
			setAnchorEl(event.currentTarget)
			setInvoiceRecordID(id)
		}
	}

	const handlSearchClick = (event: any, name: number, id: number, record: any) => {
		setSearchRecord(record)
		handleOpen()
		setInvoiceRecordID(id)
	}

	const handlAdditionalFieldClick = (event: any, name: number, id: number) => {
		setOpenAdditionalFieldsModal(true)
		setInvoiceRecordID(id)
	}

	const handleDDSwitchChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		id: number,
	) => {
		const tempList = invoiceRecords
		if (tempList) {
			tempList.forEach((item) => {
				if (item.id_ == id) {
					item.updated_username = me.username
					item.record_status = event.target.value
					updateInvoiceRecord(item, (data) => {
						removePossibleDisputes(item.id_, Number.parseInt(event.target.value), () => { })
					})
				}
			})
			setInvoiceRecords(tempList)
		}
	}

	const handleDDReasonChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		id: number,
	) => {
		if (invoiceRecords) {
			const recList = invoiceRecords
			let refObj = undefined
			recList.forEach((element) => {
				if (element.id_ == id) {
					element.updated_username = me.username
					element.dispute_reason_id = event.target.value
					refObj = element
				}
			})

			if (refObj) {
				updateInvoiceRecord(refObj, (data) => {
					setInvoiceRecords(recList)
				})
			}
		}
	}

	const handlePopupClose = () => {
		setAnchorEl(null)
	}

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(Number.parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleOpen = () => {
		setOpenModal(true)
	}

	const handleClose = () => {
		setOpenModal(false)
	}

	const handleCloseSearchModal = () => {
		setOpenSearchModal(false)
	}

	const handleMultiManifestClose = () => {
		setOpenMultiManifestModal(false)
	}

	const handleCloseAdditionalFields = () => {
		setOpenAdditionalFieldsModal(false)
	}

	const handleOpenDuplicateModal = (recordID: number) => {
		setDuplicateInvoiceID(recordID)
		setOpenDuplicateModal(true)
	}

	const handleCloseDuplicateModal = () => {
		setOpenDuplicateModal(false)
	}

	const handleMultiManifestClick = (_event: any, manifestData: any) => {
		setMultiManifestData(manifestData)
		setOpenMultiManifestModal(true)
	}

	const getRebillTotal = (row) => {
		let rebillTotal = 0
		if (row && row.matches && row.matches.length > 0) {
			row.matches.forEach((item) => {
				if (item.ar_chuse) {
					rebillTotal += Number.parseFloat(item.ar_chuse)
				}
				if (item.ar_trx) {
					rebillTotal += Number.parseFloat(item.ar_trx)
				}
			})
		}
		return rebillTotal
	}

	const findManifestSsl = (row) => {
		let manifest_ssl = ""
		if (row && row.manifest_num && row.matches) {
			row.matches.forEach((item) => {
				if (item && item.full_manifest === row.manifest_num && item.ssl) {
					manifest_ssl = item.ssl
				}
			})
		}
		return manifest_ssl
	}
	const getCreditsForTransactions = (disputeTransactions: any[]) => {
		const finalTransactionCredit = disputeTransactions[disputeTransactions.length - 1]
		return "$" + formatCurrency(finalTransactionCredit?.credit_amt || 0)
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined
	const maxHeight = props.maxHeight ? props.maxHeight.toString() + "px" : "100%"

	if (invoiceRecords) {
		return (
			<div id="invoicePanel" style={{ height: "100%", width: "100%" }}>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={openModal}
					onClose={handleClose}>
					<div style={modalStyle} className={classes.modal}>
						<RecordSearch
							isClosed={handleClose}
							invoiceRecordID={invoiceRecordID}
							assignTriniumManifest={setManifestSelected}
							unassignTriniumManifest={setManifestUnselected}
							invoiceRecordData={searchRecord}
							disableAssignment={!props.isUnlocked}
						/>
					</div>
				</Modal>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={openMultiManifestModal}
					onClose={handleMultiManifestClose}>
					<div style={modalStyle} className={classes.modal}>
						<MultiManifestDisplay
							isClosed={handleMultiManifestClose}
							manifestsData={multimanifestData}
						/>
					</div>
				</Modal>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={openSearchModal}
					onClose={handleCloseSearchModal}>
					<div style={modalStyle} className={classes.modal}>
						<InvRecFilter setSearchText={setSearchText} setResetSearch={setResetSearch} />
					</div>
				</Modal>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={openAdditionalFieldsModal}
					onClose={handleCloseAdditionalFields}>
					<div style={modalStyle} className={classes.modal}>
						<AdditionalFields invoiceRecordID={invoiceRecordID} />
					</div>
				</Modal>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={openDuplicateModal}
					onClose={handleCloseDuplicateModal}>
					<div style={modalStyle} className={classes.modal}>
						<DuplicateDisplay
							invoiceRecID={duplicateInvoiceID}
							statusList={props.statusData ? props.statusData : []}
							disputeReasons={props.disputeReasonData ? props.disputeReasonData : []}
							PopoverControl={props.PopoverControl}
						/>
					</div>
				</Modal>
				<Box style={{ height: "auto", width: "100%", padding: 0 }}>
					<TableToExcel
						id="tte_ccmRecon"
						sheet="Worksheet"
						table="invoiceRec"
						fileName={"Invoice_Rec_" + Date.now()}
						buttonText=""
						className="">
					</TableToExcel>
					<Button
						style={{ float: "right", padding: 3 }}
						onClick={() => {
							setOpenSearchModal(true)
						}}>
						<SearchIcon fontSize="small" />
					</Button>
					{searchText && (
						<Button
							style={{ float: "right", padding: 3 }}
							onClick={() => {
								setResetSearch(true)
							}}>
							<CancelIcon fontSize="small" />
						</Button>
					)}
				</Box>
				<TableContainer style={{ maxHeight: maxHeight, width: "100%" }}>
					<Table id="invoiceRec" stickyHeader={true} size={"small"}>
						<SortableTableHeaders
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={HeaderDataInvoiceRecons}
							viewTrinium={props.viewTrinium}
							viewReason={props.viewReason}
							viewReview={props.viewReview}
							viewPopOver={props.viewPopOver}
							quickSearchDisplays={props.quickSearchDisplays}
						/>
						<TableBody>
							{stableSort(invoiceRecords, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<TableRow
											key={row.id_}
											hover
											onDoubleClick={(event) => handleDoubleClick(event, index, row.id_)}
											onClick={(event) => handleClick(event, index)}
											tabIndex={-1}
											selected={selectedItem === index}>
											{props.viewPopOver && (
												<TableCell style={{ padding: 1 }}>
													<IconButton onClick={(event) => handleDoubleClick(event, index, row.id_)}>
														<LaunchIcon />
													</IconButton>
												</TableCell>
											)}
											{props.viewTrinium && (
												<TableCell style={{ padding: 1 }}>
													<IconButton
														onClick={(event) => handlSearchClick(event, index, row.id_, row)}
														className={classes.triniumIcon}></IconButton>
												</TableCell>
											)}
											{props.viewReview && (
												<TableCell style={{ padding: 1 }}>
													<TextField
														disabled={
															(props.isComplete && !props.isUnlocked && row.ap_summary_id) ||
															(props.isComplete && row.ap_summary_id) ||
															(props.isComplete && !props.isUnlocked) ||
															props.disableControls
														}
														size="small"
														select
														variant="outlined"
														value={row.record_status}
														style={{ maxWidth: 100 }}
														onChange={(event) => handleDDSwitchChange(event, row.id_)}>
														{props.statusData.map((status: any) => {
															return (
																<MenuItem key={status.id_} value={status.id_}>
																	{status.status}
																</MenuItem>
															)
														})}
													</TextField>
												</TableCell>
											)}
											{props.viewReason && (
												<TableCell style={{ padding: 1 }}>
													<TextField
														disabled={
															(props.isComplete && !props.isUnlocked && row.ap_summary_id) ||
															(props.isComplete && row.ap_summary_id) ||
															(props.isComplete && !props.isUnlocked) ||
															props.disableControls
														}
														size="small"
														select
														variant="outlined"
														value={row.dispute_reason_id}
														name={row.dispute_reason}
														style={{ maxWidth: 200 }}
														onChange={(event) => handleDDReasonChange(event, row.id_)}>
														<MenuItem value={null}>{"noReason"}</MenuItem>
														{props.disputeReasonData
															? props.disputeReasonData.map((item) => {
																return (
																	<MenuItem key={item.id_} value={item.id_}>
																		{item.reason}
																	</MenuItem>
																)
															})
															: null}
													</TextField>
												</TableCell>
											)}
											{props.quickSearchDisplays && (
												<>
													<TableCell style={{ padding: 1 }}>
														<Link to={"/invoiceworkflow/" + row.iep_invoice_id}>{row.invoice_num}</Link>
													</TableCell>
													<TableCell style={{ padding: 1 }}>
														{row.invoice_dt ? formatDateMMDDYYYY(row.invoice_dt) : ""}
													</TableCell>
													<TableCell style={{ padding: 1 }}>
														<Link to={"/invoiceworkflow/" + row.iep_invoice_id + "?record=" + row.id_}>{row.id_}</Link>
													</TableCell>
												</>
											)}
											{!props.quickSearchDisplays && (
												<TableCell style={{ padding: 1 }}>{row.id_}</TableCell>
											)}
											<TableCell style={{ padding: 1 }}>
												{row.chassis ? row.chassis.toUpperCase() : ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.container1 ? row.container1.toUpperCase() : ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.dt_out ? formatDateMMDDYYYY(row.dt_out) : ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.dt_in ? formatDateMMDDYYYY(row.dt_in) : ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.out_facility ? row.out_facility.toUpperCase() : ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.in_facility ? row.in_facility.toUpperCase() : ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>{row.billed_days_out}</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.total_billed ? "$ " + formatCurrency(row.total_billed) : ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.iep_pool ? row.iep_pool.toUpperCase() : ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.manifest_num ? row.manifest_num.toUpperCase() : ""}
												{row.matches && row.matches.length > 1 ? (
													<IconButton
														style={{ padding: 0 }}
														onClick={(event) => handleMultiManifestClick(event, row.matches)}>
														<AddIcon />
													</IconButton>
												) : (
													""
												)}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{findManifestSsl(row).toUpperCase()}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.terminal_name
													? row.terminal_name.toUpperCase()
													: row.matches
														? row.matches.length > 0
															? row.matches[0].site.toUpperCase()
															: ""
														: ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.customer_name ? row.customer_name.toUpperCase() : ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{"$" + formatCurrency(row.total_rebill)}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{"$" + formatCurrency(row.profit_loss)}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.dispute_transactions && row.dispute_transactions.length > 0
													? getCreditsForTransactions(row.dispute_transactions)
													: ""}
											</TableCell>
											<TableCell style={{ padding: 1 }}>
												{row.is_duplicate ? (
													<Button
														onClick={() => {
															handleOpenDuplicateModal(row.id_)
														}}>
														YES
													</Button>
												) : (
													"NO"
												)}
											</TableCell>
											{props.viewPopOver && (
												<TableCell>
													<TableCell style={{ padding: 1 }}>
														<IconButton
															onClick={(event) => handlAdditionalFieldClick(event, index, row.id_)}>
															<LaunchIcon />
														</IconButton>
													</TableCell>
												</TableCell>
											)}
										</TableRow>
									)
								})}
						</TableBody>
					</Table>
					{props.PopoverControl && (
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							PaperProps={{ style: { minWidth: 1200 } }}
							onClose={handlePopupClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}>
							{props.PopoverControl &&
								props.PopoverControl(
									invoiceRecordID,
									(props.isComplete && !props.isUnlocked) || props.disableControls,
								)}
						</Popover>
					)}
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[200]}
					component="div"
					count={invoiceRecords.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
				/>
			</div>
		)
	} else {
		return (
			<div>
				<h3>No Records</h3>
			</div>
		)
	}
}
